import { formatDivisionOption } from '@app/utils'

import { useAppContextSelector } from '../AppContext'
import { SelectField, SelectFieldProps } from './SelectField'

export function DivisionSelectField(props: SelectFieldProps<any, any, any>) {
  const divisions: any = useAppContextSelector((state) => state.divisions)

  const { data, status, error } = divisions

  return <SelectField options={data.map(formatDivisionOption)} isLoading={status === 'loading'} {...props} />
}

'use client'

import { NewLeadFormData } from 'src/app-components/Create/Lead'
import { NewSiteFormData } from 'src/app-components/Create/Site'

import { useRequestCallback } from '@fsg/next-auth/useRequest'

import {
  AccountFormData,
  AccountRequest,
  AccountResponse,
  CampaignFormData,
  CampaignResponse,
  ContactFormData,
  ContactRequest,
  ContactResponse,
  ExpenseFormData,
  ExpenseRequest,
  ExpenseResponse,
  LeadConversionRequest,
  LeadFormData,
  LeadRequest,
  LeadResponse,
  NewAccountFormData,
  NewCampaignFormData,
  NewContactFormData,
  NewExpenseRequest,
  OpportunityFormData,
  OpportunityRequest,
  OpportunityResponse,
  SiteFormData,
  SiteRequest,
  SiteResponse,
} from '@app/types'
import {
  prepareAccountFormDataForRequest,
  prepareContactFormDataForRequest,
  prepareExpenseRequest,
  prepareLeadFormDataForRequest,
  prepareNewCampaignRequest,
  prepareNewLeadDataForRequest,
  prepareNewSiteFormDataForRequest,
  prepareOpportunityFormDataForRequest,
  prepareSiteFormDataForRequest,
} from '@app/utils'

import { ENDPOINTS } from '../constants/endpoints'
import { prepareCampaignRequest } from '../utils/campaignsTransformer'
import { Transformer, useEngageServiceFactory } from './useEngageServiceFactory'

// TODO: All services should aim to employ an update and a create transformer, and generic should be the respective New[Entity]FormData and [Entity]FormData types, so that calls to `service.update` and `service.create` can always just pass the react-hook-form data object as is. #code-review
// ? See useCampaignsService for example
// Some services do not currently employ a `create` transformer, and some services are using too many types for the first argument in the useEngageServiceFactory generic
// These things indicate inconsistency in how we have set up our component and data logic
// ?

// Note: when `getSession` from next-auth is properly implemented in our fsg-next-auth package, we will be able to turn all these into singleton classes outside of the react lifecycle. Currently, we cannot access the session outside of the AuthContextProvider, so these services must be implemented as react hooks.
// ? Alternative approach WOULD be to use the NextJS API route handlers, but we cannot get the server session methods to work there either, which may have something to do with our custom provider config.

export function useLeadService(transformers?: Transformer) {
  const { request } = useRequestCallback()
  const leadService = useEngageServiceFactory<LeadFormData | LeadRequest | NewLeadFormData, LeadResponse>(ENDPOINTS.LEADS, {
    update: prepareLeadFormDataForRequest,
    create: prepareNewLeadDataForRequest,
    ...transformers,
  })

  return {
    ...leadService,
    convert: (leadId: string, data: LeadConversionRequest) => {
      return request<any>({ path: ENDPOINTS.CONVERT_LEAD(leadId), body: JSON.stringify(data), method: 'POST' })
    },
  }
}

export function useContactService(transformers?: Transformer) {
  return useEngageServiceFactory<NewContactFormData | ContactFormData | ContactRequest, ContactResponse>(ENDPOINTS.CONTACTS, {
    update: prepareContactFormDataForRequest,
    ...transformers,
  })
}

export function useAccountService(transformers?: Transformer) {
  return useEngageServiceFactory<NewAccountFormData | AccountRequest | AccountFormData, AccountResponse>(ENDPOINTS.ACCOUNTS, {
    update: prepareAccountFormDataForRequest,
    ...transformers,
  })
}

export function useOpportunityService(transformers?: Transformer) {
  return useEngageServiceFactory<OpportunityRequest | OpportunityFormData, OpportunityResponse>(ENDPOINTS.OPPORTUNITIES, {
    update: prepareOpportunityFormDataForRequest,
    ...transformers,
  })
}

export function useSiteService(transformers?: Transformer) {
  return useEngageServiceFactory<NewSiteFormData | SiteRequest | SiteFormData, SiteResponse>(ENDPOINTS.SITES, {
    update: prepareSiteFormDataForRequest,
    create: prepareNewSiteFormDataForRequest,
    ...transformers,
  })
}

export function useCampaignsService(transformers?: Transformer) {
  return useEngageServiceFactory<NewCampaignFormData | CampaignFormData, CampaignResponse>(ENDPOINTS.CAMPAIGNS, {
    update: prepareCampaignRequest,
    create: prepareNewCampaignRequest,
    ...transformers,
  })
}

export function useExpensesService(campaignId: string, transformers?: Transformer) {
  return useEngageServiceFactory<NewExpenseRequest | ExpenseFormData | ExpenseRequest, ExpenseResponse>(ENDPOINTS.CAMPAIGN_EXPENSES(campaignId), {
    update: prepareExpenseRequest,
    ...transformers,
  })
}

export * from './useNavRoutes'
export * from './useFirstLoginCheck'
export * from './useActivityTracking'
import React, { useCallback, useEffect, useState } from 'react'
import { ErrorMessage } from '@hookform/error-message'
// import { AddressAutofillRetrieveResponse } from '@mapbox/search-js-core';
// import { AddressAutofill, config } from '@mapbox/search-js-react';
import { Controller, UseFormReturn } from 'react-hook-form'
import Select from 'react-select'

import { useNotification } from '@fsg/gui-bits'
import { Status } from '@fsg/next-auth/types'
import { useRequestCallback } from '@fsg/next-auth/useRequest'

import { useAppContextSelector } from '@app/components/AppContext'
import { IndustriesField } from '@app/components/Form'
import { SelectField } from '@app/components/Form/SelectField'
import { TextField } from '@app/components/Form/TextField'
import { ENDPOINTS, VALIDATIONS } from '@app/constants'
import { CountryType, Industries, NewAccountFormData, SelectOption, StatesResponseType, StateType } from '@app/types'
import { formatCountryOption, getSelectStyles, onBlurWorkaround, renderErrorMessage, sortCountries } from '@app/utils'

import { Label } from '../ui'
import { Field } from '../ui/Field'
import { FieldGroup } from '../ui/FieldGroup'

type Props = {
  form: UseFormReturn<NewAccountFormData>
}

// const MAPBOX_TOKEN = process.env.NEXT_PUBLIC_MAPBOX_TOKEN
// config.accessToken = MAPBOX_TOKEN

export function NewAccountForm({ form }: Props) {
  const notification = useNotification()
  const { request } = useRequestCallback()
  const countries = useAppContextSelector((state) => state.countries)
  const [industries, setIndustries] = useState<Industries[]>([])
  const [states, setStates] = useState<StateType[]>([])
  const [statesStatus, setStatesStatus] = useState<Status>('idle')
  const [statesError, setStatesError] = useState<Error | null>(null)

  const {
    register,
    formState: { errors },
    setValue,
  } = form

  const callingCodeOptions = countries.map((country) => ({
    label: `+${country.telephoneCode}`,
    value: country,
  }))

  // const Autofill = AddressAutofill as any
  // const handleAutoFill = async (feature: AddressAutofillRetrieveResponse) => {
  //   if (feature.features.length) {
  //     const address = feature.features[0].properties
  //     if (!address.address_line2) {
  //       setValue('address.line2', '')
  //     }
  //     const country = countries.find((country) => country.alpha2code === address.country_code.toUpperCase())
  //     const states = await getStates(country.name)
  //     setStates(states)

  //     if (country) setValue('address.country', { label: country.name, value: country })
  //     else setValue('address.country', null)

  //     const state = states.find((state) => state.code === address.address_level1 || state.name === address.address_level1)

  //     if (state) setValue('address.state', { label: state.name, value: state.name })
  //     else setValue('address.state', null)
  //   }
  // }

  const getIndustries = useCallback(async () => {
    return await request<Industries[]>({ path: ENDPOINTS.INDUSTRIES })
  }, [request])

  const formatStateOption = (state: StateType) => ({ label: state.name, value: state.name })

  const getStates = useCallback(
    async (countryCode: string) => {
      const res = await request<StatesResponseType>({ path: ENDPOINTS.GEO.STATES(countryCode || 'US'), method: 'GET' })
      return res
    },
    [request],
  )

  const handleGetStatesSuccess = useCallback((states: StatesResponseType) => {
    setStates(states)
    setStatesStatus('success')
    setStatesError(null)
  }, [])

  const handleGetStatesFail = useCallback((error: Error) => {
    setStates([])
    setStatesStatus('error')
    setStatesError(error as Error)
  }, [])

  useEffect(() => {
    // ? Don't fetch anything if the sheet is closed
    if (!open) return
    let ignore = false

    async function getDefaultData() {
      try {
        // If any of the requests fail, the entire block will exit and raise an error, which is typically not safe
        // Practically, this is ok here because if any one of these doesn't work, the whole form is unusable since all fields are required
        const [states, industries] = await Promise.all([getStates('US'), getIndustries()])

        if (ignore) return

        handleGetStatesSuccess(states)
        setIndustries(industries)
      } catch (error) {
        handleGetStatesFail(error as Error)
        setIndustries([])
        console.error('Error getting default data')
        notification.api.notify({
          message: 'Error getting default data',
          type: 'warning',
        })
      }
    }
    getDefaultData()

    return () => {
      ignore = true
    }
  }, [getIndustries, getStates, handleGetStatesFail, handleGetStatesSuccess, notification.api])

  return (
    <div className="grid">
      <FieldGroup title="Company Name" description="What is the full legal name of this business.">
        <TextField label="Name" name="name" required rules={{ required: VALIDATIONS.REQUIRED }} />
      </FieldGroup>
      <FieldGroup title="Phone Number" description="Please provide the primary public facing phone number for this account.">
        <SelectField name="callingCode" label="Calling Code" options={callingCodeOptions} isClearable={false} onBlur={onBlurWorkaround} />
        <TextField
          name="phone"
          label="Phone Number"
          rules={{
            required: VALIDATIONS.REQUIRED,
            pattern: VALIDATIONS.PHONE_PATTERN,
          }}
        />
        <TextField label="Extension" name="extension" />
      </FieldGroup>
      <FieldGroup title="Company Address" description="Please provide the legal address for this account.">
        {/* <Autofill
          accessToken={MAPBOX_TOKEN}
          onRetrieve={handleAutoFill}
          theme={{
            variables: {
              fontFamily: 'sans-serif',
            },
          }}
        > */}
        <div className="flex flex-col">
          <Field
            label="Street Address"
            {...register('address.line1', { required: VALIDATIONS.REQUIRED })}
            errors={errors}
            autoComplete="address-line1"
            onBlur={onBlurWorkaround} // ! doesn't work with AutoFill?!
          />
        </div>
        {/* </Autofill> */}
        <Field label="Line 2" requiredLabel={false} {...register('address.line2')} errors={errors} autoComplete="address-line2" />
        <Field label="City" {...register('address.city', { required: VALIDATIONS.REQUIRED })} errors={errors} autoComplete="address-level2" />
        <div className="grid grid-cols-2 gap-xl">
          <div className="flex flex-col">
            <Label htmlFor="state" label="State" />
            <Controller
              name="address.state"
              control={form.control}
              rules={{ required: VALIDATIONS.REQUIRED }}
              render={({ field }) => (
                <Select
                  {...field}
                  options={states.length ? states.map(formatStateOption) : []}
                  isLoading={statesStatus === 'loading'}
                  {...getSelectStyles()}
                  onBlur={onBlurWorkaround}
                />
              )}
            />
            <ErrorMessage errors={errors} name="state" render={renderErrorMessage} />
          </div>
          <div className="flex flex-col">
            <Field label="Zip" {...register('address.postalCode', { required: VALIDATIONS.REQUIRED })} errors={errors} autoComplete="postal-code" />
          </div>
        </div>
        <div>
          {VALIDATIONS.styleLabel('Country')}
          <Controller
            name="address.country"
            control={form.control}
            rules={{ required: VALIDATIONS.REQUIRED }}
            render={({ field }) => (
              <Select
                {...field}
                isClearable={false}
                isSearchable
                options={countries.length ? countries.sort(sortCountries).map(formatCountryOption) : []}
                onChange={async (param) => {
                  const newValue = param as unknown as SelectOption<CountryType>
                  // If new selection is the same as previous, exit early
                  if (form.getValues('address.country.value.id') === newValue.value.id) return
                  field.onChange(newValue)
                  form.setValue('address.state', null)
                  setStatesStatus('loading')
                  try {
                    const states = await getStates(newValue.value.alpha3code)
                    handleGetStatesSuccess(states)
                  } catch (error) {
                    console.error(error)
                    handleGetStatesFail(error as Error)
                  }
                }}
                {...getSelectStyles()}
                onBlur={onBlurWorkaround}
              />
            )}
          />
        </div>
      </FieldGroup>
      <FieldGroup title="Industry" description="Select at least one industry to which this account belongs.">
        <div>
          <Label label="Industries" htmlFor="industries" />
          <IndustriesField name="industries" label="Industries" industries={industries} />
          <ErrorMessage errors={errors} name="industries" render={renderErrorMessage} />
        </div>
      </FieldGroup>
    </div>
  )
}
